import { ICategorieFrais } from "@/pages/bureau/@models/frais.model";
import {
  IPieceType,
  IQualiteInter,
} from "@/pages/bureau/@models/intervenants.model";
import {
  IProjetFrais,
  IProjetPartial,
  IProjetPhase,
  IProjetSimple,
} from "@/pages/bureau/@models/projet.model";
import {
  IAffaireStatut,
  ICorpsMetier,
  ISociete, 
  ISocieteInfos, 
  ITag,
  IUser,
} from "@/pages/bureau/@models/societe.model";
import axios from "./index";
 import { IFiltresBIM } from "@/pages/bureau/@models/societe.model";
 import { IType_User } from "@/pages/bureau/@models/projet.model";
 import { ITypeHeure } from "@/pages/bureau/store-temp/pointages.temp";
 import { IPrefsSociete } from "@/pages/bureau/store-temp/prefs.temp";
 import { IModuleUsersInterne } from "@/pages/bureau/@models/fil.model";

const BASE_ENDPOINT = "BUREAU";

export const societesService = {
  /**
   * Rexupere les informations de la societe index
   */
  getSociete() {
    return axios.get<{
      societe: ISocieteInfos;
    }>(`USER/SOCIETE`);
  },
  getUG() {
    return axios.get<{
      users:{
        user:{
          nom : string;
          prenom : string; 
          index : number;
        },
        societe: ISociete;
      }[],

      ug:{
        nom : string;
        prenom : string;
        email : string;
        user_logged : number;
      },
    }>(`USER/UG`);
  },


  
  getUserImage(userIndex: number): Promise<Blob> {
    return axios.get(`BUREAU/USER/IMAGE/${userIndex}`, {
      responseType: "blob",
    });
  },

  /**
   * Recupere toutes les societes
   */
  getSocietes() {
    return axios.get<{
      societes: ISociete[];
    }>(`USER/SOCIETES/BUREAU`);
  },

  /**
   * Recupere toutes les societes
   */
  postSocietes(email:string) {
    return axios.post<{
      societes: ISociete[];
    }>(`USER/SOCIETES/UG`,{email});
  },

  getSocietePrefs(societeIndex: number) {
    return axios.get<{
      prefs: IPrefsSociete;
    }>(`BUREAU/SOCIETE/${societeIndex}/PREFS`);
  },

  getPolice(nomPolice: string) {
    return axios.get<{
      b64: string;
    }>(`BUREAU/POLICE/${nomPolice}`);
  },

  /**
   * Recupere toutes les  statisiques pour la page accueil
   */
  getStatsAccueil() {
    return axios.get<{
      frais?: {
        dateDebut: string;
        montant: number;
      };
      planning?: {
        dateDebut: string;
        dateFin: string;
        nbHeures: number;
      };
      tache?: {
        nbTaches: number;
        nbTachesRetard: number;
      };
      rdv?: {
        nbRdv: number;
        nbRdvJour: number;
      };
      projet?: {
        nbProjets: number;
        nbProjetsEnCours: number;
      };
      intervenant?: {
        nbIntervenants: number;
      };
      base?: {
        nbArticles: number;
      };
      events?: {
        nonLus: number;
      };
    }>(`${BASE_ENDPOINT}/ACCUEIL`);
  },

  /**
   * Recupere tous les status des affaires
   */
  getAffaireStatuts() {
    return axios.get<{
      statuts: IAffaireStatut[];
    }>(`${BASE_ENDPOINT}/AFFAIRES-STATUTS`);
  },

  /**
   * Pour recupere les données nécéssaire au fonction du module Frais + authorisation
   * @returns
   */
  getModuleFrais() {
    return axios.get<{
      authorisation: boolean;
      projets: IProjetFrais[];
      projetsPhase: IProjetPhase[];
      categories: ICategorieFrais[];
    }>(`${BASE_ENDPOINT}/MODULES/FRAIS`);
  },
  /**
   * Pour recupere les données nécéssaire au fonction du module Frais + authorisation
   * @returns
   */
  getModulePointage() {
    return axios.get<{
      authorisation: boolean;
      projets: IProjetFrais[];
      projetsPhase: IProjetPhase[];
      categories: ICategorieFrais[];
      typeHeure: ITypeHeure[];
    }>(`${BASE_ENDPOINT}/MODULES/POINTAGES`);
  },

  getModuleFil() {
    return axios.get<{
      projets: IProjetSimple[];
      users: IModuleUsersInterne[];
    }>(`${BASE_ENDPOINT}/MODULES/FIL`);
  },

  /**
   * Pour recupere les données nécéssaire au fonction du module Projets + authorisation
   * @returns
   */
  getModuleProjets() {
    return axios.get<{
      authorisation: boolean;
      avancements: IAffaireStatut[];
      filtres: IFiltresBIM[];
      type_user: IType_User[];
      referents: string[];
    }>(`${BASE_ENDPOINT}/MODULES/PROJETS`);
  },

  /**
   * Pour telecharger un document de la table
   * @param index
   * @returns
   */
  downloadDocument(index, predicate?): Promise<Blob> {
    return axios.get(`${BASE_ENDPOINT}/DOCUMENTS/${index}`, {
      responseType: "blob",
      onDownloadProgress: predicate,
    });
  },

  downloadLightImageDocument(index, predicate?): Promise<Blob> {
    return axios.get(`${BASE_ENDPOINT}/DOCUMENTS/${index}/IMAGE-LIGHT`, {
      responseType: "blob",
      onDownloadProgress: predicate,
    });
  },

  /**
   * Pour telecharger un PE de la table
   * @param index
   * @returns
   */
  downloadPE(index, predicate?): Promise<Blob> {
    return axios.get(`${BASE_ENDPOINT}/DOWNLOADS/PE/${index}`, {
      responseType: "blob",
      onDownloadProgress: predicate,
    });
  },

  downloadPELightImage(index, predicate?): Promise<Blob> {
    return axios.get(`${BASE_ENDPOINT}/DOWNLOADS/PE/${index}/IMAGE-LIGHT`, {
      responseType: "blob",
      onDownloadProgress: predicate,
    });
  },

  /**
   * Pour recupere les données nécéssaire au fonction du module Intervenants + authorisation
   * @returns
   */
  getModuleIntervenants() {
    return axios.get<{
      authorisation: boolean;
      qualites: IQualiteInter[];
      corpsMetier: ICorpsMetier[];
      tags: ITag[];
      typesPiece: IPieceType[];
      filtres: IFiltresBIM[];
    }>(`${BASE_ENDPOINT}/MODULES/INTERVENANTS`);
  },

  /**
   * Pour recupere les utilisateurs
   * @returns
   */
  getUsers() {
    return axios.get<{
      users: IUser[];
    }>(`${BASE_ENDPOINT}/SOCIETE/USERS`);
  },

  /**
   * Pour enregistrer temporairement un gros fichiers b64 découpé en plusieurs strings sous une clé
   * @returns
   */
  saveBigFile(key: string, data: string, part: number, total: number) {
    return axios.put(`${BASE_ENDPOINT}/BIG-FILE`, { key, data, part, total });
  },

  saveFile(formData: FormData) {
    return axios.put(`${BASE_ENDPOINT}/FILE`, formData);
  },
};
