import { IResponse } from "@/@types/api";
import store from "@/pages/bureau/store";
import { SettingsModule } from "@/pages/bureau/store/modules/settings.module";
import { UserModule } from "@/pages/bureau/store/modules/user.module";
import { isValidStatus } from "@/utils";
import axios, { Axios } from "axios";
import { useRouter } from "vue-router";
import { checkTimeout } from "@/pages/bureau/utils/indexeddb";

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV == "production"
      ? `${window.location.href.split("/web/")[0]}/API/V3/`
      : process.env.VUE_APP_BIMOFFICE_API_URL,
  headers: {
    "x-api-key": process.env.VUE_APP_BOFFICE_API as string,
  },
});

// Config global des Headers
axiosInstance.interceptors.request.use(
  async (config) => {
    await checkTimeout();

    //Si stockage async alors on attent que la restoration
    if ("restored" in store) {
      await(store as any).restored;
    }
    if (!config.headers) {
      config.headers = {
        "x-api-key": import.meta.env.VITE_BOFFICE_API as string,
        Authorization: "",
        IdentifiantMachine: "",
      } as any;
    }

    // Authentification
    if (UserModule.getToken) {
      config.headers["Authorization"] = UserModule.getToken;
      config.headers["IdentifiantMachine"] = UserModule.getIdMachine;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Config global des réponses
axiosInstance.interceptors.response.use(
  (response): any => {
    const res = response.data as IResponse;
    // Gestion des requetes invalides
    if (!isValidStatus(response.status)) {
      if (SettingsModule.showApiErrorMessage) {
        //
      }
      return Promise.reject(new Error(res.message || "Error"));
    }
    // Gestion des requete valides
    else {
      return res;
    }
  },
  (error) => {
     if(error?.response?.status == 403){
      UserModule.ResetToken();
      const href = location.href.split("/bureau")[0];
      const forceLoginUrl = href + "/bureau#/error403";
      location.href = forceLoginUrl;
      return error;
    }
    // Si erreur d'authentifaction, on reset le token et on redirige vers la page Login
    if (error?.request?.status == 401 && !location.href.includes("#/error")) {
       
      UserModule.ResetToken();
      //location.reload();
      const href = location.href.split("/bureau")[0];
      const forceLoginUrl = href + "/bureau#/login";
      location.href = forceLoginUrl;
    }
    if (SettingsModule.showApiErrorMessage) {
      //
    }
    //return Promise.reject(error)
    return error;
  }
);

export default axiosInstance;
