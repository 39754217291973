<template>
    <Datepicker
      v-bind="mergedProps"
      v-on="$attrs"
    />
  </template>
  
  <script>
  import { defineComponent, computed } from 'vue';
  import Datepicker from "@vuepic/vue-datepicker";
  
  export default defineComponent({
    name: 'CustomDatepicker',
    components: {
      Datepicker,
    },
    props: Datepicker.props,
    setup(props, { attrs }) {
      const mergedProps = computed(() => ({
        ...props, 
        locale: 'fr', 
        selectText: 'Valider',
        cancelText: 'Retour',
        ...attrs,
      }));
  
      return {
        mergedProps,
      };
    },
  });
  </script>
  