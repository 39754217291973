import { Component, createApp, nextTick } from "vue";

import { createPinia } from "pinia";

import App from "./App.vue";
import router from "@/pages/bureau/router/index";
//import { registerSW } from "virtual:pwa-register";
import store, { storage } from "@/pages/bureau/store/index";
import axiosInstance from "@/pages/bureau/api/index";
import VueAxios from "vue-axios";
import i18n from "./lang";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

// Pour TailwindCSS
import "@/assets/index.css";

import "./style.css";

import "flowbite";

import { UserModule } from "./store/modules/user.module";

import registerRules from "@/pages/bureau/rules";

import moment from "moment";
import "moment/locale/fr";

import useLayout, { LayoutEnum } from "./composables/useLayout";
import VueSortable from "vue3-sortablejs";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { is403, isPreviewInUrl, isPreviewLogin } from "@/utils";

import { Collapse } from "vue-collapsed";
 
const pinia = createPinia();
const app = createApp(App);

registerRules();

const requireComponent = require.context("@/components/global", true, /\.vue$/);
registerComponent(requireComponent, app);

app.use(store);
app.use(pinia);
app.use(router);
app.use(VueAxios, axiosInstance);
app.use(i18n);

app.use(VueSortable);
dayjs.locale("fr");
app.component("DatePicker", Datepicker);
app.component("CustomCollapse", Collapse);

app.mount("#app");

const whiteList = ["login","preview","preview_login","403" ]

const { currentLayout, setLayout, setDrawer, setNavbar } = useLayout();

// Guard
router.beforeEach(async (to, from, next) => {
  if (isPreviewLogin()) {
    if (to.name == "preview_login") {
      next();
    }
    //si on clique sur un lien alors on ne redirige pas
    return;
  }

  if (isPreviewInUrl()) {
    if (to.name == "preview") {
      next();
    }
    //si on clique sur un lien alors on ne redirige pas
    return;
  }


  //Si stockage async alors on attent que la restoration soit terminé
  if ("restored" in store) {
    await (store as any).restored;
  }

  // Si la page est dans la whitelist alors on ne verifie pas ses roles / token
  if (whiteList.includes(to.name as string)) {
    next();
  } else {
    // On regarde dabord si l'user à un token, sinon on le renvoi au login
    // TODO: tester si le token est toujours valid
    if (UserModule.getToken) {
      if (UserModule.isAdmin || UserModule.checkRoles(to)) {
        next();
      } else {
        next("/");
      }
    } else {
      next({
        name: "login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  }
});

router.afterEach((to, from, next) => {
  if (to.name == "preview") {
    setNavbar(true);
    setLayout(LayoutEnum.default);
    return;
  }
  //  Etape 2: Layout
  if (to && to.meta && to.meta.layout) {
    setNavbar(!((to.meta as any).layout.showNavbarOnMobile === false));

    // On permet de changer le layout sur certaine route non "strict"
    if (!(to.meta as any).layout["strict"]) {
      if (to.query.layout) {
        setLayout(to.query.layout as LayoutEnum);
      }
    }

    if (currentLayout.value != (to.meta as any).layout["type"]) {
      setLayout((to.meta as any).layout["type"]);
    }
  }
});
  
moment.locale("fr", {
  months:
    "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
      "_"
    ),
  monthsShort:
    "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
  monthsParseExact: true,
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Aujourd’hui à] LT",
    nextDay: "[Demain à] LT",
    nextWeek: "dddd [à] LT",
    lastDay: "[Hier à] LT",
    lastWeek: "dddd [dernier à] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "une heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === "M";
  },

  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? "PD" : "MD";
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

/**
 *
 * @param requireComponent chemin d'enregistrement des fichiers en global
 * @param app application vue avant le mount
 */
function registerComponent(requireComponent, app) {
  requireComponent.keys().forEach((fileName) => {
    // Obtenez la configuration du composant
    const componentConfig = requireComponent(fileName);

    // Obtenez le nom du composant en utilisant le nom du fichier
    const componentName = fileName
      .split("/")
      .pop()
      .replace(/\.\w+$/, "");

    // Enregistrez le composant globalement
    app.component(componentName, componentConfig.default);
  });
}

//permet de supprimer l'erreur de resizeObserver
class CalmResizeObserver extends ResizeObserver {
  constructor(callback: ResizeObserverCallback) {
    super((entries, observer) => {
      requestAnimationFrame(() => {
        callback(entries, observer);
      });
    });
  }
}
window.ResizeObserver = CalmResizeObserver;