import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/pages/bureau/store";
import { filService } from "@/pages/bureau/api/fil.api";
import { awaitData, deepCopy, isValidStatus } from "@/utils";
import filTemp from "@/pages/bureau/store-temp/fil.temp";
import { blobToBase64 } from "./projets.module";
import { RouteLocationNormalizedLoaded, useRoute, useRouter } from "vue-router";
import { societesService } from "@/pages/bureau/api/societes.api";
import {
  ICreateGroup,
  IFdcNonLuREQ,
  IGroupParticipant,
  IGroupTitles,
  IOptionalGroupParticipant,
} from "@/pages/bureau/@models/fil.model";
import router from "@/pages/bureau/router";
import accessTemp from "@/pages/bureau/store-temp/access.temp";
import useLayout from "@/pages/bureau/composables/useLayout";
import { showNotif } from "@/utils/notif";
import { UserModule } from "./user.module";
import { User } from "@/components/global/icons";

@Module({ dynamic: true, store, name: "fil", namespaced: true })
class Fil extends VuexModule {
  public categoriesOpened: { [index: number]: boolean } = {};

  @Mutation
  public clearCategoriesOpened() {
    this.categoriesOpened = {};
  }
  @Mutation
  public setCategorie(catName: string) {
    if (!this.categoriesOpened) {
      this.categoriesOpened = {};
    }
    this.categoriesOpened[catName] = !this.categoriesOpened[catName];
  }

  @Action({ rawError: true })
  public async fetchCategories() {
    const { data, status } = await filService.getCategories(filTemp.filters);
    if (!isValidStatus(status) || !data) {
      console.error("La récupération des catégories a échoué");
      return;
    }
    filTemp.categories = data?.categories || [];
    filTemp.categoriesIsLoaded = true;
    setTimeout(async () => {
      // fetch images async
      for (const cat of filTemp.categories) {
        for (const group of cat.groups) {
          if (!filTemp.groupImages[group.uidGroup]) {
            const data = await filService.getCategorieImage(group.uidGroup);
            if (data instanceof Blob) {
              filTemp.groupImages[group.uidGroup] = await blobToBase64(data);
            }
          }
        }
      }
    }, 500);
  }

  private defaultTitle = "";

  @Mutation
  private setDefaultTitle(title: string) {
    this.defaultTitle = title;
  }

  /**
   * @ Récupère les fils non lus
   * @ Retourne un boolean si il faut refresh les données
   */
  @Action({ rawError: true })
  public async fetchFilsNonLus() {
    const { data, status } = await filService.getFilsNonLus();
    if (!isValidStatus(status) || !data) {
      console.error("La récupération des fils non lus a échoué");
      return;
    }
    filTemp.usersParticipants = data?.users || [];

    filTemp.groupUnread = data?.nonLus;
    filTemp.myGroupUnread = {};
    data.nonLus
      .filter((g) => g.indexTable == UserModule.index)
      .forEach((g) => {
        if (filTemp.myGroupUnread[g.uidGroup] != g.nbMessNonLu) {
          filTemp.myGroupUnread[g.uidGroup] = g.nbMessNonLu;
        }
      });

    const isDifferent = filTemp.groupUnreadIsDifferent(
      data.nonLus.filter((g) => g.indexTable == UserModule.index)
    );

    const unread: number = data.nonLus
      .filter((g) => g.indexTable == UserModule.index)
      .reduce((acc, g) => acc + g.nbMessNonLu, 0);
    const unreadFormatted: string = unread > 9 ? "9+" : unread.toString();

    if (this.defaultTitle == "") {
      this.setDefaultTitle(document.title);
    }
    document.title =
      unread > 0
        ? `(${unreadFormatted}) ` + this.defaultTitle
        : this.defaultTitle;

    //show notification

    if (unread > 0 && isDifferent) {
      const message =
        unread > 1
          ? `Vous avez ${unread} nouveaux messages`
          : "Vous avez un nouveau message";
      showNotif("Nouveau message", message);
    }

    return isDifferent;
  }

  @Action({ rawError: true })
  public async fetchDataByRoute(route: RouteLocationNormalizedLoaded) {
    this.fetchModule();
    FilModule.runListener();
  }

  @Action({ rawError: true })
  public async runListener() {
    if (await accessTemp.await("FIL-READ")) {
      if (!filTemp.listener) {
        filTemp.listener = true;

        const { showDrawer } = useLayout();
        while (filTemp.listener) {
          const route = router.currentRoute.value;

          if (
            route?.name?.toString() &&
            (route?.name?.toString().includes("fdc") ||
              route?.name?.toString() == "home" ||
              showDrawer.value ||
              window.innerWidth >= 720)
          ) {
            const isGroupUnreadDifferent = await this.fetchFilsNonLus();
            if (isGroupUnreadDifferent) {
              this.fetchCategories();
            } else {
              if (filTemp.categories.length === 0) {
                this.fetchCategories();
              }
            }

            if (route?.params?.uidGroup?.toString()) {
              if (
                Object.keys(filTemp.myGroupUnread).includes(
                  route.params.uidGroup.toString()
                )
              ) {
                //tester si il y  a deja des messages
                if (
                  Object.keys(
                    filTemp.groupMessage[route.params.uidGroup.toString()]
                  ).length > 0
                ) {
                  this.fetchPaginatedMessages({
                    uidGroup: route.params.uidGroup.toString(),
                    page: 0,
                  });
                }
              }
            }

            await new Promise((resolve) => setTimeout(resolve, 10000));
          } else {
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
        filTemp.listener = false;
      }
    }
  }

  @Action({ rawError: true })
  public async fetchPaginatedMessages({
    uidGroup,
    page,
    filtreFavoris,
    filtreDocuments,
  }: {
    uidGroup: string;
    page: number;
    filtreFavoris?: boolean;
    filtreDocuments?: boolean;
  }) {
    const { data, status } = await filService.getPaginatedMessages(
      uidGroup,
      page,
      {
        filtreFavoris,
        filtreDocuments,
      }
    );
    if (!isValidStatus(status) || !data) {
      console.error("La récupération des messages a échoué");
      return;
    }
    const { messages, pagination } = data;
    if (filTemp.groupMessage[uidGroup] === undefined) {
      filTemp.groupMessage[uidGroup] = [];
    }
    messages.forEach((message) => {
      if (!filTemp.groupMessage[uidGroup].find((m) => m.uid === message.uid)) {
        filTemp.groupMessage[uidGroup].push(message);
      }
    });
    filTemp.groupMessagePagination = pagination;
    setTimeout(async () => {
      this.fetchImagesLight({
        uidGroup,
        canScrollDown: page == 0,
      });
    }, 500);
  }

  @Action({ rawError: true })
  public async fetchImagesLight({
    uidGroup,
    canScrollDown,
  }: {
    uidGroup: string;
    canScrollDown: boolean;
  }) {
    if (!filTemp.groupMessage[uidGroup]) {
      return;
    }
    for (const message of filTemp.groupMessage[uidGroup]) {
      if (message.estFichier && !message.imageBlob && message.fichier) {
        const imagesExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
        const extension = message?.fichier?.nom?.split(".")?.pop();
        if (imagesExtensions.includes(extension?.toLowerCase() ?? "")) {
          if (message.type == "doc") {
            const data = await societesService.downloadLightImageDocument(
              message.fichier.indexTable
            );
            if (data instanceof Blob) {
              message.imageBlob = data;
            }
          } else if (message.type == "pe") {
            const data = await societesService.downloadPELightImage(
              message.fichier.indexTable
            );
            if (data instanceof Blob) {
              message.imageBlob = data;
            }
          }
        }
      }
    }

    if (canScrollDown) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      window.dispatchEvent(new Event("b-bottom-scroll--force-bottom"));
    }
  }

  @Mutation
  public async clearMessages(uidGroup: string) {
    filTemp.groupMessage[uidGroup] = [];
    filTemp.groupMessagePagination = {};
  }

  @Mutation
  public async clearFilter(uidGroup: string) {
    filTemp.groupFilter[uidGroup] = undefined;
  }

  @Action({ rawError: true })
  public async setFilLu(uidGroup: string) {
    await filService.setFilLu(uidGroup);
    this.fetchFilsNonLus();
  }

  @Action({ rawError: true })
  public async createMessage({
    uidGroup,
    message,
  }: {
    uidGroup: string;
    message: string;
  }) {
    await filService.createMessage(uidGroup, message);
    await this.fetchPaginatedMessages({ uidGroup, page: 0 });
  }

  @Action({ rawError: true })
  public async updateMessage({
    uidGroup,
    uidMessage,
    message,
  }: {
    uidGroup: string;
    uidMessage: string;
    message: string;
  }) {
    const { status, data, request } = await filService.updateMessage(
      uidGroup,
      uidMessage,
      message
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La modification du message a échoué!"
      );
    }
    //mettre a jour le message
    filTemp.groupMessage[uidGroup].find((m) => m.uid == uidMessage).message =
      message;
  }

  @Action({ rawError: true })
  public async removeMessage({
    uidGroup,
    uidMessage,
  }: {
    uidGroup: string;
    uidMessage: string;
  }) {
    const { status, data, request } = await filService.removeMessage(
      uidGroup,
      uidMessage
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La modification du message a échoué!"
      );
    }
    //supprimer le message
    filTemp.groupMessage[uidGroup].find(
      (m) => m.uid == uidMessage
    ).participants[
      filTemp.groupMessage[uidGroup].find(
        (m) => m.uid == uidMessage
      ).myUidParticipant
    ].del = 1;
  }

  @Action({ rawError: true })
  public async createMessageFile({
    uidGroup,
    fileb64,
    fileName,
  }: {
    uidGroup: string;
    fileb64: string;
    fileName: string;
  }) {
    await filService.createMessageDoc(uidGroup, fileb64, fileName);
    await this.fetchPaginatedMessages({ uidGroup, page: 0 });
  }

  @Action({ rawError: true })
  public async fetchModule() {
    const { status, data, request } = await societesService.getModuleFil();
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La récupération des pointages a échoué !"
      );
    }
    if (data?.projets) {
      filTemp.moduleProjets = data.projets;
    }
    if (data?.users) {
      filTemp.moduleUsersInterne = data.users;
    }
  }

  /**
   *
   * @ create/join group
   */
  @Action({ rawError: true })
  public async createGroup({
    type,
    data,
    routeName = "",
  }: {
    type: string;
    data: ICreateGroup;
    routeName?: string;
  }) {
    const result = await filService.createGroup(type, data);
    if (!isValidStatus(result?.status) || !result?.data) {
      const error = Error(
        getErrorMessage(result?.request) ?? "La création du groupe a échouée !"
      );
    }

    if (result?.data?.uidGroup) {
      await this.fetchCategories();
      if (routeName == "fdc-conversation") {
        await this.fetchPaginatedMessages({
          uidGroup: result.data.uidGroup,
          page: 0,
        });
      }
      return result.data.uidGroup;
    } else {
      return null;
    }
  }

  @Action({ rawError: true })
  public async fetchGroupParticipants(uidGroup: string) {
    const { status, data, request } = await filService.getFilParticipants(
      uidGroup
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération des participants a échouée !"
      );
      console.error(error);
      return;
    }
    filTemp.groupParticipants[uidGroup] = data.participants;
  }

  /**
   *
   * @addArray : array of user index to add
   * @removeArray : array of participant uid to remove
   */
  @Action({ rawError: true })
  public async updateGroupParticipants({
    uidGroup,
    addArray,
    removeArray,
    addNamesArray,
    removeNamesArray,
  }: {
    uidGroup: string;
    addArray: number[];
    removeArray: string[];
    addNamesArray: string[];
    removeNamesArray: string[];
  }) {
    const { status, request } = await filService.updateFilParticipants(
      uidGroup,
      {
        addArray,
        removeArray,
        addNamesArray: addNamesArray.sort((a, b) => a.localeCompare(b)),
        removeNamesArray: removeNamesArray.sort((a, b) => a.localeCompare(b)),
      }
    );
    if (!isValidStatus(status)) {
      const error = Error(
        getErrorMessage(request) ??
          "La mise à jour des participants a échouée !"
      );
      console.error(error);
      return;
    }

    filTemp.groupImages[uidGroup] = undefined;

    await this.fetchCategories();

    await this.fetchGroupParticipants(uidGroup);
    await this.fetchPaginatedMessages({ uidGroup, page: 0 });
  }

  @Action({ rawError: true })
  public async updateGroupParticipant({
    uidGroup,
    uidParticipant,
    data,
  }: {
    uidGroup: string;
    uidParticipant: string;
    data: IOptionalGroupParticipant;
  }) {
    const { status, request } = await filService.updateFilParticipant(
      uidGroup,
      uidParticipant,
      data
    );
    if (!isValidStatus(status)) {
      const error = Error(
        getErrorMessage(request) ??
          "La mise à jour des participants a échouée !"
      );
      console.error(error);
      return;
    }
    await this.fetchCategories();
  }

  @Action({ rawError: true })
  public async setMessageFavoris({
    uidGroup,
    uidMessage,
    estFavoris,
  }: {
    uidGroup: string;
    uidMessage: string;
    uidParticipant: string;
    estFavoris: boolean;
  }) {
    const { status, request } = await filService.setFavoris(
      uidGroup,
      uidMessage,
      estFavoris
    );
    if (!isValidStatus(status)) {
      const error = Error(
        getErrorMessage(request) ??
          "La mise à jour des participants a échouée !"
      );
      console.error(error);
      return;
    }

    const message = filTemp.groupMessage[uidGroup].find(
      (m) => m.uid == uidMessage
    );
    if (message) {
      message.participants[message.myUidParticipant].fav = estFavoris ? 1 : 0;
    }
  }
}
export const FilModule = getModule(Fil);

function getErrorMessage(request: any): string {
  throw new Error("Function not implemented.");
}
