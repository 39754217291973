import { IResponse } from "@/@types/api";
import store from "@/pages/bureau/store";
import { SettingsModule } from "@/pages/bureau/store/modules/settings.module";
import { UserModule } from "@/pages/bureau/store/modules/user.module";
import { isValidStatus } from "@/utils";
import axios, { Axios } from "axios";
import { useRouter } from "vue-router";
import { checkTimeout } from "@/pages/bureau/utils/indexeddb";

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV == "production"
      ? `${window.location.href.split("/web/")[0]}/API/PUBLIC/V1`
      : process.env.VUE_APP_BIMOFFICE_API_URL.replace("API/V3", "API/PUBLIC/V1"),
  headers: {
    "x-api-key": process.env.VUE_APP_BOFFICE_API as string,
  },
});


export default axiosInstance;
